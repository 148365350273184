body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1 {
  font-size: 3rem;
  font-weight: normal;
  margin: 1rem 0 0.5rem 0;
}

textarea {
  background-color: transparent;
  border: none;
}

input,
select {
  font: inherit;
  color: inherit;
}

.header {
  padding: 0.3rem 1rem 0.3rem;
  text-align: left;
  cursor: pointer;
}

.numeric {
  text-align: right;
}

.data {
  padding: 0.3rem 1rem 0.3rem;
  white-space: nowrap;
  font-size: 1.2rem;
}

.data:after {
  border-bottom: 15px solid var(--accent);
  border-left: 15px solid transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 0;
  right: 0;
  width: 0;
  position: absolute;
}
.page-heading {
  padding-top: 3rem;
  padding-left: 0.6rem;
  padding-bottom: 2rem;
}
